<template>
  <div class="form">
    <v-card class="mx-auto" max-width="1000">
      <v-img class="white--text align-end" src="/img/banner.jpg">
        <v-card-title>{{ $t('requestForm.banner.renewAccess') }}</v-card-title>
      </v-img>

      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <v-form ref="form" v-model="valid" :lazy-validation="true">
              <training-section v-model="form.trainingInformation" />
              <PersonalData v-model="form.personalData" />
              <ReasonDescription v-model="form.accessReason" />
              <AssetsSection v-model="form.assets" />
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="sendButtonDisabled"
          class="px-5"
          rounded
          @click="handleSend"
        >{{ $t('actions.send') }}</v-btn>
        <v-btn color="primary" rounded outlined @click="handlePrint">{{ $t('actions.print') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-container class="grey lighten-5 pt-10"></v-container>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import validators from '../validators'

export default {
  name: 'RenewAccessForm',
  data: () => ({
    dayjs,
    ...validators,
    valid: false,
    snackbar: false,
    sendButtonDisabled: false,
    form: {
      type: 'renewal',
      trainingInformation: {
        trainingDate: null,
        isTrainingCompleted: undefined
      },
      personalData: {
        isSwedishNational: undefined,
        socialSecurityNumber: null,
        passportNumber: null,
        birthDate: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        profession: null,
        userId: null
      },
      accessReason: {
        reason: null
      },
      assets: []
    }
  }),
  computed: {
    ...mapGetters(['isValidated'])
  },
  watch: {
    isValidated (v) {
      v || this.$router.push('/')
    }
  },
  methods: {
    ...mapActions(['sendRequest', 'createMessage']),
    async handleSend () {
      if (this.$refs.form.validate()) {
        try {
          this.sendButtonDisabled = true
          await this.sendRequest(this.form)
        } finally {
          this.sendButtonDisabled = false
        }
      } else {
        this.createMessage({ text: this.$t('messages.formValidationWarning'), color: 'warning' })
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    handlePrint () {
      window.print()
    }
  }
}
</script>
